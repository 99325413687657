import { createSlice } from '@reduxjs/toolkit';

import { fetchSIInfo } from 'store/actionCreators/si';

const si = createSlice({
  name: 'si',
  // initialState: {},
  initialState: {} as SIInfo,
  // initialState: {
  //   siName: '',
  //   apiUri: '',
  //   revelationMonth: 2,
  // },
  reducers: {},
  // extraReducers: (builder) => {
  //   builder.addCase(fetchSIInfo.fulfilled, (state, { payload }) => {
  //     return { ...state, ...payload };
  //   });
  // },
  extraReducers: {
    [fetchSIInfo.fulfilled.type]: (state, action) => {
      return action.payload;
    },
    // builder.addCase(setSIBy, (state, action) => {
    //   return { ...state };
    //   // return { ...state, ...action.payload };
    // });
  },
});

export default si;
