import { createAsyncThunk } from '@reduxjs/toolkit';
import request from 'superagent';

export const fetchSIInfo = createAsyncThunk('fetchSIInfo', async () => {
  const { hostname } = window.location;
  // let apiHost = 'http://localhost:5001';
  // const reLocal = /http:\/\/localhost:.*/i;
  const reStag = /http:\/\/arke-stag.delphus.io.*/i;
  const reprod = /https:\/\/arke-prod.delphus.io.*/i;
  // let env = 'local';
  let apiHost = 'http://localhost:5001';
  // if (hostname && hostname.match(reLocal)) {
  //   // env = 'local';
  //   apiHost = 'http://localhost:5001';
  if (hostname && hostname.match(reStag)) {
    // env = 'stag';
    apiHost = 'http://arke-stag.delphus.io';
  } else if (hostname && hostname.match(reprod)) {
    // env = 'prod';
    apiHost = 'https://arke-prod.delphus.io';
  }

  // interface APIHost {
  //   apiHost: string;
  // }

  // interface Hosts {
  //   [propName: string]: APIHost;
  // }

  // const hosts: Hosts = {
  //   local: {
  //     apiHost: 'http://localhost:5001',
  //   },
  //   stag: {
  //     apiHost: 'http://arke-stag.delphus.io',
  //   },
  //   prod: {
  //     apiHost: 'https://arke-prod.delphus.io',
  //   },
  // };

  const apiUri = `${apiHost}/arke-liff/us-central1/api/si-info`;
  const response = await request
    // .get(`http://localhost:8000/doghowls/si-info`)
    .get(apiUri)
    .query({ siurl: 'doghowls' })
    .then((res) => res.body)
    .catch((err) => {
      console.log(err);
    });

  return response;
});
